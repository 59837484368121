import axios from "axios"

const axiosBase = axios.create({
  baseURL: process.env.REACT_APP_API_URL
})

export const configFormatter = (token: string) => {
  return {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }
}

export { axiosBase }
