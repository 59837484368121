export enum AbiTypesEnum {
  bool = "bool",
  address = "address",
  string = "string",
  bytes = "bytes",
  bytes8 = "bytes8",
  bytes16 = "bytes16",
  bytes24 = "bytes24",
  bytes32 = "bytes32",
  bytes40 = "bytes40",
  bytes48 = "bytes48",
  bytes56 = "bytes56",
  bytes64 = "bytes64",
  bytes72 = "bytes72",
  bytes80 = "bytes80",
  bytes88 = "bytes88",
  bytes96 = "bytes96",
  bytes104 = "bytes104",
  bytes112 = "bytes112",
  bytes120 = "bytes120",
  bytes128 = "bytes128",
  bytes136 = "bytes136",
  bytes144 = "bytes144",
  bytes152 = "bytes152",
  bytes160 = "bytes160",
  bytes168 = "bytes168",
  bytes176 = "bytes176",
  bytes184 = "bytes184",
  bytes192 = "bytes192",
  bytes200 = "bytes200",
  bytes208 = "bytes208",
  bytes216 = "bytes216",
  bytes224 = "bytes224",
  bytes232 = "bytes232",
  bytes240 = "bytes240",
  bytes248 = "bytes248",
  bytes256 = "bytes256",
  int = "int",
  int8 = "int8",
  int16 = "int16",
  int24 = "int24",
  int32 = "int32",
  int40 = "int40",
  int48 = "int48",
  int56 = "int56",
  int64 = "int64",
  int72 = "int72",
  int80 = "int80",
  int88 = "int88",
  int96 = "int96",
  int104 = "int104",
  int112 = "int112",
  int120 = "int120",
  int128 = "int128",
  int136 = "int136",
  int144 = "int144",
  int152 = "int152",
  int160 = "int160",
  int168 = "int168",
  int176 = "int176",
  int184 = "int184",
  int192 = "int192",
  int200 = "int200",
  int208 = "int208",
  int216 = "int216",
  int224 = "int224",
  int232 = "int232",
  int240 = "int240",
  int248 = "int248",
  int256 = "int256",
  uint = "uint",
  uint8 = "uint8",
  uint16 = "uint16",
  uint24 = "uint24",
  uint32 = "uint32",
  uint40 = "uint40",
  uint48 = "uint48",
  uint56 = "uint56",
  uint64 = "uint64",
  uint72 = "uint72",
  uint80 = "uint80",
  uint88 = "uint88",
  uint96 = "uint96",
  uint104 = "uint104",
  uint112 = "uint112",
  uint120 = "uint120",
  uint128 = "uint128",
  uint136 = "uint136",
  uint144 = "uint144",
  uint152 = "uint152",
  uint160 = "uint160",
  uint168 = "uint168",
  uint176 = "uint176",
  uint184 = "uint184",
  uint192 = "uint192",
  uint200 = "uint200",
  uint208 = "uint208",
  uint216 = "uint216",
  uint224 = "uint224",
  uint232 = "uint232",
  uint240 = "uint240",
  uint248 = "uint248",
  uint256 = "uint256"
}
