import { createContext, useEffect } from "react"

import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useAppDispatch } from "redux/hooks"

import { cookies } from "utility/cookies"

import toast from "react-hot-toast"

const Context = createContext(null)

export const CookieExpirationProvider = ({ children }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const data = cookies.get("userData")

  useEffect(() => {
    if (data && data.expires && (new Date()).toISOString() > data.expires) {
      dispatch(() => {
        cookies.remove("userData", { path: "/", domain: process.env.REACT_APP_COOKIE_DOMAIN })
      })

      navigate("/")
      toast.error(t("token_expired_toast"), {
        position: "top-center",
        duration: 5000
      })
    }
  }, [data])

  return (
    <Context.Provider
      value={null}
    >
      {children}
    </Context.Provider>
  )
}