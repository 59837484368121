import { AxiosResponse } from "axios"
import { axiosBase, configFormatter } from "./axiosBase"
import { WalletBalance } from "../interfaces/walletInterface"
import { configFormater } from "./configFormatter"
import { CompanyUsersByRole, CreatedCompany } from "./../interfaces/companyInterface/index.d"
import { CompanyRolesEnum } from "interfaces/sharedEnums/roles.enum"
import { NetworkEnum } from "interfaces/sharedEnums"

type TAccessToken = string
const controller = "/companies"

interface CompanyRequests {
  accessToken: TAccessToken
  companyParams: string
  body: Partial<CreatedCompany>
  sendgridBody: CreatedCompany["sendgridSettings"]
}

const getcompany = (token: string) => {
  const config = configFormater(token)
  return axiosBase.get("/companies", config)
}

const getCompanyByJWT = (token: string): Promise<AxiosResponse<{ companies: CreatedCompany[] }>> => {
  const config = configFormater(token)
  return axiosBase.get("/companies", config)
}

export const getCompanyGoTaxes = (token: string, companyId: string): Promise<AxiosResponse> => {
  const config = configFormater(token)
  return axiosBase.get(`/companies/${companyId}/goTaxes`, config)
}

const getDefaultWalletBalance = (token: string, network?: NetworkEnum): Promise<AxiosResponse<WalletBalance>> => {
  const config = { headers: { Authorization: `Bearer ${token}` } }
  const getRoute = "/users/default-wallet/balance"
  if (network) {
    return axiosBase.get(`${getRoute}?network=${network}`, config)
  }
  return axiosBase.get(getRoute, config)
}

const getWalletBalance = (
  token: string,
  walletId: string,
  network?: NetworkEnum
): Promise<AxiosResponse<WalletBalance>> => {
  const config = { headers: { Authorization: `Bearer ${token}` } }
  const getRoute = `/wallets/${walletId}/balance`
  if (network) {
    return axiosBase.get(`${getRoute}?network=${network}`, config)
  }
  return axiosBase.get(getRoute, config)
}

const updateCompanyById = (token: string, id: string, newStatus: Partial<CreatedCompany>) => {
  const config = configFormater(token)
  return axiosBase.patch(`/companies/${id}`, newStatus, config)
}

const updateCompanyBannerById = (token: string, id: string, newStatus: CreatedCompany) => {
  const config = configFormater(token)
  return axiosBase.patch(`/companies/${id}`, newStatus, config)
}

const updateCompanySendgridSettings = async ({
  companyParams,
  accessToken,
  sendgridBody
}: Pick<CompanyRequests, "accessToken" | "sendgridBody" | "companyParams">) => {
  const config = configFormatter(accessToken)
  const response: AxiosResponse = await axiosBase.patch(
    `${controller}/${companyParams}/sendgrid-settings`,
    sendgridBody,
    config
  )
  return response
}

const getBalance = (token: string, id: string) => {
  const config = configFormater(token)
  return axiosBase.get(`/store/${id}/myway-balances`, config)
}

const getCompanyUsersByRole = (token: string, companyId: string, role: CompanyRolesEnum) => {
  const config = configFormater(token)
  return axiosBase.get(`/companies/${companyId}/users-roles/role/${role}`, config)
}

const removeAssociatedValidatorFromCompany = (token: string, companyId: string, userId: string) => {
  const config = configFormater(token)
  return axiosBase.delete(`/companies/${companyId}/users-roles/${userId}`, config)
}

export const getCompanyWallets = (token: string, companyId: string): Promise<AxiosResponse> => {
  const config = configFormater(token)
  return axiosBase.get(`/companies/${companyId}/wallets`, config)
}

export const getCompanyCollectionValidators = (token: string, companyId: string): Promise<AxiosResponse> => {
  const config = configFormater(token)
  return axiosBase.get(`/companies/${companyId}/collection-validators`, config)
}

const getCompanyCollections = async (token: string, companyId: string) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` }
  }
  return await axiosBase.get(`/companies/${companyId}/collections/manage`, config)
}

export {
  getcompany,
  updateCompanyById,
  getBalance,
  getCompanyByJWT,
  updateCompanyBannerById,
  updateCompanySendgridSettings,
  getCompanyUsersByRole,
  removeAssociatedValidatorFromCompany,
  getDefaultWalletBalance,
  getWalletBalance,
  getCompanyCollections
}
