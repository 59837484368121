import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

import { getMyWhiteLabelConfig } from "services/whiteLabelRequest"

export const getWhiteLabelConfig = createAsyncThunk("white-label/setings", async () => {
  try {

    const { data } = process.env.REACT_APP_ENABLE_DEV_FEATURES === "true" ? await getMyWhiteLabelConfig(`dd93ea5c-5f22-4b2a-8276-73a72c0bf614`) : { data: {} }

    return data
  } catch (error) {
    throw new Error(error.response.data)
  }
})

export const whiteLabelConfigSlice = createSlice({
  name: "whiteLabelConfig",
  initialState: {
    whiteLabelConfig: {},
    loading: false,
    errorMessage: undefined
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getWhiteLabelConfig.pending, (state) => {
        state.loading = true
      })
      .addCase(getWhiteLabelConfig.fulfilled, (state, { payload }) => {
        state.loading = false
        state.whiteLabelConfig = payload
      })
      .addCase(getWhiteLabelConfig.rejected, (state, { payload }) => {
        state.loading = false
        state.errorMessage = payload
      })
  }
})

export default whiteLabelConfigSlice.reducer
