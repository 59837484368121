
import { Spinner } from "reactstrap"

const SpinnerComponent = () => {

  return (
    <div className="fallback-spinner app-loader">
      <Spinner color='secondary' size='lg'  />
    </div>
  )
}

export default SpinnerComponent
